import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, onSnapshot } from "@firebase/firestore";
import { Oval } from "react-loader-spinner"; // Import the loader component

import NavBar from "../components/NavBar";
import { db } from "../firebase/firebase";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  const articlesCollectionRef = collection(db, "articles");

  useEffect(() => {
    const unsub = onSnapshot(
      articlesCollectionRef,
      (snapshot) => {
        let stuff = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        setArticles(stuff);
        setLoading(false); // Data fetched, set loading to false
      },
      (err) => alert(err)
    );

    return () => {
      unsub();
    };
  }, [articlesCollectionRef]);

  return (
    <>
      <NavBar />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          {loading ? ( // Display loader when loading is true
            <div className="flex justify-center items-center h-screen">
              <Oval color="#00BFFF" height={80} width={80} />
            </div>
          ) : (
            <div className="flex flex-wrap -m-4">
              {articles.map((article) => {
                return (
                  <div className="p-4 md:w-1/3" key={article.id}>
                    <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                      <img
                        className="lg:h-48 md:h-36 w-full object-cover object-center"
                        src={
                          article.attachment
                            ? article.attachment
                            : "https://dummyimage.com/720x400"
                        }
                        alt="blog"
                      />
                      <div className="p-6">
                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                          {article.title.toUpperCase()}
                        </h1>
                        <p className="leading-relaxed mb-3">
                          {`${article.content.slice(0, 99)}...`}
                        </p>
                        <div className="flex items-center flex-wrap ">
                          <Link
                            className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                            to={`/articles/${article.id}`}
                          >
                            See More
                            <svg
                              className="w-4 h-4 ml-2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M5 12h14" />
                              <path d="M12 5l7 7-7 7" />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Articles;
