import React, { useEffect, useState } from "react";
import { collection, onSnapshot, doc, getDoc } from "@firebase/firestore";
import { db, auth } from "../firebase/firebase";
import { Tab, TabList, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Oval } from "react-loader-spinner";
import { useAuthState } from "react-firebase-hooks/auth";
import NavBar from "../components/NavBar";
import { BookOpen, Download } from "react-feather";

const Books = () => {
  const [books, setBooks] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("cbc");
  const [loading, setLoading] = useState(true);
  const [user, loadingAuth] = useAuthState(auth);
  const [isPremium, setIsPremium] = useState(false);
  const booksCollectionRef = collection(db, "books");

  const convertToFlipbook = async (pdfLink) => {
    const apiKey = "69bb9179c755edc6";
    try {
      const response = await fetch(
        `https://heyzine.com/api1/rest?pdf=${encodeURIComponent(
          pdfLink
        )}&k=${apiKey}&d=0&pn=1`
      );
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        throw new Error(data.message || "Failed to convert PDF to flipbook");
      }
    } catch (error) {
      console.error("Error converting PDF to flipbook:", error.message);
      return null;
    }
  };

  useEffect(() => {
    const unsub = onSnapshot(
      booksCollectionRef,
      async (snapshot) => {
        const bookDataPromises = snapshot.docs.map(async (doc) => {
          const data = doc.data();
          // Only convert if there's no existing flipbook URL
          if (!data.flipbookUrl) {
            try {
              const flipbook = await convertToFlipbook(data.attachment);
              return {
                id: doc.id,
                ...data,
                flipbookUrl: flipbook ? flipbook.url : null,
              };
            } catch (error) {
              console.error("Error converting PDF to flipbook:", error);
              return {
                id: doc.id,
                ...data,
                flipbookUrl: null,
              };
            }
          } else {
            return {
              id: doc.id,
              ...data,
            };
          }
        });

        const bookData = await Promise.all(bookDataPromises);
        setBooks(bookData);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );

    return () => {
      unsub();
    };
  }, [booksCollectionRef]);

  useEffect(() => {
    const checkSubscription = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (
          userDoc.exists() &&
          userDoc.data().subscriptionStatus === "premium"
        ) {
          setIsPremium(true);
        }
      }
    };

    checkSubscription();
  }, [user]);

  const handleTabChange = (category) => {
    setSelectedCategory(category);
  };

  const filteredBooks = books.filter(
    (book) => book.category === selectedCategory
  );

  return (
    <>
      <NavBar />
      {!isPremium && (
        <div className="bg-indigo-600 px-4 py-3 text-white">
          <p className="text-center text-sm font-medium">
            Enjoying the content?{" "}
            <a
              href={`https://wa.me/+254701967152?text=Hi.%20I%20am%20interested%20in%20paying%20for%20premium%20to%20access%20the%20full%20content`}
              className="inline-block underline"
            >
              Pay for Premium
            </a>{" "}
            to access all videos!
          </p>
        </div>
      )}
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          <Tabs>
            <TabList>
              <Tab onClick={() => handleTabChange("cbc")}>CBC</Tab>
              <Tab onClick={() => handleTabChange("chuo-kikuu")}>SECONDARY</Tab>
              <Tab onClick={() => handleTabChange("hadithi")}>STORIES</Tab>
            </TabList>
          </Tabs>
          {loading || loadingAuth ? (
            <div
              className="flex justify-center items-center h-screen"
              style={{ minHeight: "50vh" }}
            >
              <Oval color="#00BFFF" height={80} width={80} />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
                {filteredBooks.map((book, index) => {
                  if (index === 0 || isPremium) {
                    return (
                      <div className="p-4" key={book.id}>
                        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                          <div className="p-6">
                            <img
                              src={book.cover || "https://placehold.co/400x600"}
                              alt={book.title}
                              style={{
                                height: "256px",
                                width: "auto",
                                objectFit: "cover",
                              }}
                            />
                            <h1 className="title-font text-lg font-medium text-gray-900 my-3">
                              {book.title.toUpperCase()}
                            </h1>
                            <p className="leading-relaxed mb-3">
                              {book.description}
                            </p>
                            <div className="flex items-center flex-wrap ">
                              <div className="flex items-center hover:underline">
                                <a
                                  href={book.flipbookUrl || book.attachment}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-indigo-800 inline-flex items-center mr-2 md:mb-2 lg:mb-0"
                                >
                                  Read
                                </a>
                                <BookOpen size={16} color="indigo" />
                              </div>
                              <div className="flex items-center hover:underline">
                                <a
                                  href={`https://wa.me/+254701967152?text=Hi.%20I%20am%20interested%20in%20purchasing%20the%20book:%20${encodeURIComponent(
                                    book.title
                                  )}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-green-500 inline-flex items-center ml-4 mr-2 md:mb-2 lg:mb-0"
                                >
                                  Download
                                </a>
                                <Download size={16} color="green" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="p-4" key={book.id}>
                        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                          <div className="flex items-center justify-center h-64 bg-gray-200">
                            <div className="text-gray-500 text-lg flex flex-col justify-center items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width={50}
                                height={50}
                                viewBox="0 0 50 50"
                                fill="gray"
                              >
                                <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.300781 20 6 21.300781 6 23 L 6 47 C 6 48.699219 7.300781 50 9 50 L 41 50 C 42.699219 50 44 48.699219 44 47 L 44 23 C 44 21.300781 42.699219 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 25 30 C 26.699219 30 28 31.300781 28 33 C 28 33.898438 27.601563 34.6875 27 35.1875 L 27 38 C 27 39.101563 26.101563 40 25 40 C 23.898438 40 23 39.101563 23 38 L 23 35.1875 C 22.398438 34.6875 22 33.898438 22 33 C 22 31.300781 23.300781 30 25 30 Z" />
                              </svg>

                              <p className="text-center">Premium Content</p>
                            </div>
                          </div>
                          <div className="p-6">
                            <p className="leading-relaxed mb-3">
                              Please upgrade to premium to access this book.
                            </p>
                            <div className="flex items-center justify-center">
                              <a
                                href={`https://wa.me/+254701967152?text=Hi.%20I%20am%20interested%20in%20paying%20for%20premium%20to%20access%20the%20full%20content`}
                                className="text-indigo-800 inline-flex items-center"
                              >
                                Pay for Premium
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              {filteredBooks.length === 0 && (
                <div className="p-4 md:w-1/3">
                  <div className="h-full w-full p-10 border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden md:w-80 lg:w-96">
                    <p className="text-lg font-medium text-gray-900 mb-3">
                      No books available in this category.
                    </p>
                    <p className="leading-relaxed mb-3">
                      We will bring content soon. Please check back later.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Books;
