import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

import NavBar from "../components/NavBar";

const Menu = () => {
  return (
    <>
      <NavBar />
      <div className="flex justify-center m-10">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-4 lg:grid-cols-4 lg:gap-4">
          <div className="card w-72 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
              <img
                src="https://images.unsplash.com/photo-1485846234645-a62644f84728?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1459&q=80"
                alt="Shoes"
                className="rounded-xl"
              />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">Videos</h2>
              <p>
                Get to watch videos on the relevant topic to help increase your
                Swahili language skills.
              </p>
              <div className="card-actions">
                <Link
                  to="/videos"
                  className="btn bg-primary text-white border-none"
                >
                  See More
                </Link>
              </div>
            </div>
          </div>
          <div className="card w-72 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
              <img
                src="https://images.unsplash.com/photo-1524578271613-d550eacf6090?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                alt="Books"
                className="rounded-xl"
              />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">Library</h2>
              <p>
                Gain full access to books published and written by reputable
                publishers.
              </p>
              <div className="card-actions">
                <Link
                  to="/books"
                  className="btn bg-primary text-white border-none"
                >
                  See More
                </Link>
              </div>
            </div>
          </div>
          <div className="card w-72 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
              <img
                src="https://images.unsplash.com/photo-1485988412941-77a35537dae4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=896&q=80"
                alt="Shoes"
                className="rounded-xl"
              />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">Blog Articles</h2>
              <p>View insightful, relevant and learner-friendly articles.</p>
              <div className="card-actions">
                <Link
                  to="/articles"
                  className="btn bg-primary text-white border-none"
                >
                  See More
                </Link>
              </div>
            </div>
          </div>
          <div className="card w-72 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
              <img
                src="https://images.unsplash.com/photo-1583361704493-d4d4d1b1d70a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                alt="Dictionary"
                className="rounded-xl"
              />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">Dictionary</h2>
              <p>Expand your knowledge by using an updated dictionary.</p>
              <div className="card-actions">
                <Link
                  to="/dictionary"
                  className="btn bg-primary text-white border-none"
                >
                  See More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Menu;
