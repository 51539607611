import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/firebase";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import logo from "../assets/logo.jpg";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Barua pepe ni lazima")
      .email("Barua pepe si sahihi"),
    password: Yup.string().required("Nenosiri ni lazima"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await setPersistence(auth, browserLocalPersistence);

      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;

      const userDoc = await getDoc(doc(db, "users", user.uid));

      if (!userDoc.exists()) {
        // Set user as free if no document found
        await setDoc(doc(db, "users", user.uid), {
          email: user.email,
          subscriptionStatus: "free",
        });
      }

      navigate("/menu");
    } catch (error) {
      const errorMessage =
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
          ? "Wrong email or password."
          : "Something went wrong.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div className="navbar bg-primary text-primary-content">
        <NavLink to="/" className="flex items-center">
          <img
            src={logo}
            alt="Logo"
            className="navbar-logo w-20 h-20 rounded-full"
          />
          <span className="btn btn-ghost text-xl md:text-3xl text-secondary custom-font">
            Mswahilispot
          </span>
        </NavLink>
      </div>
      <div className="hero min-h-screen">
        <form
          onSubmit={formik.handleSubmit}
          className="form-control w-full max-w-xs"
        >
          <label className="label">
            <span className="label-text">Email</span>
          </label>
          <input
            type="email"
            className={`input input-bordered w-full max-w-xs ${
              formik.touched.email && formik.errors.email ? "input-error" : ""
            }`}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-error">{formik.errors.email}</div>
          ) : null}

          <label className="label">
            <span className="label-text">Password</span>
          </label>
          <input
            type="password"
            className={`input input-bordered w-full max-w-xs ${
              formik.touched.password && formik.errors.password
                ? "input-error"
                : ""
            }`}
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-error">{formik.errors.password}</div>
          ) : null}

          <button
            type="submit"
            className="btn btn-primary my-2 w-full"
            disabled={loading === true}
          >
            Log In
          </button>
          <Link to="/register" className="btn btn-secondary">
            Register
          </Link>
          <Link className="text-primary underline italic mt-1" to="/reset">
            Forgot Password
          </Link>
        </form>
      </div>
    </>
  );
};

export default Login;
