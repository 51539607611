// in config.js
import { createChatBotMessage } from "react-chatbot-kit";

const botName = "Mswahilispot Bot";

const config = {
  initialMessages: [
    createChatBotMessage(
      `Hello! I am ${botName}, your assistant. I'm here to help you navigate the Mswahilispot application, the main source of Kiswahili resources!`
    ),
    createChatBotMessage(
      `To start, are you looking to access translation services? If so, type T. For other services, type N.`
    ),
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#5ccc9d",
    },
  },
};

export default config;
