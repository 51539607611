import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <h1>Privacy Policy</h1>
      <p>Effective Date: 15/11/2023</p>

      <h2>Introduction</h2>
      <p>
        Welcome to MswahiliSpot and Easy Swahili, applications provided by
        MswahiliSpot ("we," "our," or "us"). We value your privacy and are
        committed to protecting your personal information. This Privacy Policy
        outlines how we collect, use, disclose, and safeguard your information
        when you use our mobile applications, MswahiliSpot and Easy Swahili
        (collectively referred to as the "Apps").
      </p>
      <p>
        By using our Apps, you agree to the collection and use of information in
        accordance with this policy. If you do not agree with the terms of this
        Privacy Policy, please do not access the Apps.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We collect several types of information to provide and improve our Apps,
        including:
      </p>

      <h3>Personal Information</h3>
      <ul>
        <li>
          <strong>Contact Information:</strong> Name, email address, phone
          number, and any other contact information you provide.
        </li>
        <li>
          <strong>Account Information:</strong> Usernames, passwords, and other
          security-related information.
        </li>
      </ul>

      <h3>Usage Data</h3>
      <ul>
        <li>
          <strong>App Usage Information:</strong> Details about your use of the
          Apps, including time spent on the Apps, pages visited, and other usage
          patterns.
        </li>
      </ul>

      <h3>Cookies and Tracking Technologies</h3>
      <p>
        We use cookies and similar tracking technologies to track activity on
        our Apps and store certain information.
      </p>

      <h2>How We Use Your Information</h2>
      <p>We use the collected information for various purposes, including:</p>
      <ul>
        <li>
          <strong>To Provide and Maintain the Apps:</strong> To ensure the Apps
          function correctly and to troubleshoot issues.
        </li>
        <li>
          <strong>To Improve the Apps:</strong> To understand how users interact
          with the Apps and to enhance user experience.
        </li>
        <li>
          <strong>To Communicate with You:</strong> To send you updates,
          notifications, and other information related to the Apps.
        </li>
        <li>
          <strong>To Personalize Your Experience:</strong> To tailor content and
          features to your preferences and interests.
        </li>
        <li>
          <strong>To Ensure Security:</strong> To monitor and protect the
          security of our Apps and users.
        </li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>We may share your information with:</p>
      <ul>
        <li>
          <strong>Service Providers:</strong> Third-party companies and
          individuals that provide services on our behalf, such as analytics,
          hosting, and customer support.
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger,
          acquisition, or sale of all or a portion of our assets, your
          information may be transferred.
        </li>
        <li>
          <strong>Legal Requirements:</strong> If required by law or in response
          to valid requests by public authorities, we may disclose your
          information.
        </li>
      </ul>

      <h2>Data Security</h2>
      <p>
        We strive to use commercially acceptable means to protect your personal
        information. However, no method of transmission over the Internet or
        method of electronic storage is 100% secure. While we aim to protect
        your personal data, we cannot guarantee its absolute security.
      </p>

      <h2>Your Data Protection Rights</h2>
      <p>
        Depending on your location, you may have the following rights regarding
        your personal data:
      </p>
      <ul>
        <li>
          <strong>Access and Update:</strong> You have the right to access and
          update your personal information.
        </li>
        <li>
          <strong>Deletion:</strong> You can request the deletion of your
          personal data by contacting us at elishapan.wachira@gmail.com.
        </li>
        <li>
          <strong>Restriction:</strong> You can request to restrict the
          processing of your data.
        </li>
        <li>
          <strong>Objection:</strong> You have the right to object to our
          processing of your data.
        </li>
        <li>
          <strong>Data Portability:</strong> You can request a copy of your data
          in a structured, machine-readable format.
        </li>
      </ul>

      <h2>Children’s Privacy</h2>
      <p>
        Our Apps are intended for use by everyone. However, we do not knowingly
        collect personal information from children under the age of 13 without
        verifiable parental consent. If we become aware that we have collected
        personal data from a child under 13 without such consent, we will take
        steps to delete that information.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:elishapan.wachira@gmail.com">
            elishapan.wachira@gmail.com
          </a>
        </li>
        <li>
          <strong>Address:</strong> Nairobi, Kenya
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
