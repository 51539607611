// in ActionProvider.jsx
import React from "react";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleYes = () => {
    const botMessage = createChatBotMessage(
      'To access Translation Services, click on the "Translation Services" button, which will open a WhatsApp chat with an English-Swahili and Gikuyu-Swahili translator.'
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleConfused = () => {
    const botMessage = createChatBotMessage(
      `I apologize for the confusion. Please refresh the page and try again, or choose another suggested option.`
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleOther = () => {
    const botMessage = createChatBotMessage(
      `In this app, there are resources that you, as a Kiswahili learner, can access, including blog articles, a dictionary, and more. To begin, click the "Register" button to create an account or log in if you already have one.`
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleYes,
            handleConfused,
            handleOther,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
