// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAP7JdmN-aSHDzGoy3PujjnylfkcAcimyw",
  authDomain: "kiswahili-learning-app.firebaseapp.com",
  projectId: "kiswahili-learning-app",
  storageBucket: "kiswahili-learning-app.appspot.com",
  messagingSenderId: "444814244365",
  appId: "1:444814244365:web:33c1ba82395defe60b7344",
  measurementId: "G-FTPH23E3XF",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
