import React, { useEffect, useState } from "react";
import { getDocs, collection, doc, getDoc } from "@firebase/firestore";
import NavBar from "../components/NavBar";
import { db, auth } from "../firebase/firebase";
import { Oval } from "react-loader-spinner"; // Import the loader component
import { useAuthState } from "react-firebase-hooks/auth";

const Dictionary = () => {
  const [words, setWords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, loadingAuth] = useAuthState(auth);
  const [isPremium, setIsPremium] = useState(false);
  const wordsCollectionRef = collection(db, "kamusi");

  useEffect(() => {
    const getWords = async () => {
      const data = await getDocs(wordsCollectionRef);
      setWords(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoading(false); // Data fetched, set loading to false
    };
    getWords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkSubscription = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (
          userDoc.exists() &&
          userDoc.data().subscriptionStatus === "premium"
        ) {
          setIsPremium(true);
        }
      }
    };

    checkSubscription();
  }, [user]);

  const filteredWords = words
    .sort((a, b) => {
      let textA = a.word.toUpperCase();
      let textB = b.word.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .filter((word) => {
      if (isPremium) {
        return true;
      } else {
        const firstLetter = word.word.charAt(0).toUpperCase();
        return firstLetter >= "A" && firstLetter <= "C";
      }
    });

  return (
    <>
      <NavBar />
      <section className="text-gray-600 body-font blobs">
        {!isPremium && (
          <div className="bg-indigo-600 px-4 py-3 text-white">
            <p className="text-center text-sm font-medium">
              You're currently viewing words from A-C.{" "}
              <a
                href={`https://wa.me/+254701967152?text=Hi.%20I%20am%20interested%20in%20paying%20for%20premium%20to%20access%20the%20full%20content`}
                className="inline-block underline"
              >
                Upgrade to premium{" "}
              </a>{" "}
              to access the full dictionary.
            </p>
          </div>
        )}
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-3xl text-indigo-500 tracking-widest font-extrabold title-font mb-1">
              KAMUSI
            </h1>
            <h2 className="text-2xl font-medium title-font mb-4 text-gray-900">
              EXPAND YOUR KNOWLEDGE
            </h2>
          </div>
          {loading || loadingAuth ? ( // Display loader when loading is true
            <div className="flex justify-center items-center h-screen">
              <Oval color="#00BFFF" height={80} width={80} />
            </div>
          ) : (
            <div className="flex flex-col w-full">
              {filteredWords.map((word) => {
                return (
                  <div className="m-2" key={word.id}>
                    <h1 className="font-extrabold">{word.word}</h1>
                    <p>{word.definition}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Dictionary;
