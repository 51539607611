import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/firebase";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import logo from "../assets/logo.jpg";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Barua pepe ni lazima")
      .email("Barua pepe si sahihi"),
    password: Yup.string()
      .required("Nenosiri ni lazima")
      .min(6, "Nenosiri lazima liwe na angalau herufi 6"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        email: values.email,
        subscriptionStatus: "free", // Default subscription status
      });

      toast.success("You have successfully signed up. You can now login", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate("/login");
    } catch (error) {
      const errorMessage =
        error.code === "auth/email-already-in-use"
          ? "The email you used is already registered to another account."
          : "Something went wrong. Please try again";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div className="navbar bg-primary text-primary-content">
        <NavLink to="/" className="flex items-center">
          <img
            src={logo}
            alt="Logo"
            className="navbar-logo w-20 h-20 rounded-full"
          />
          <span className="btn btn-ghost text-xl md:text-3xl text-secondary custom-font">
            Mswahilispot
          </span>
        </NavLink>
      </div>
      <div className="flex flex-col justify-center items-center mt-40">
        <form
          onSubmit={formik.handleSubmit}
          className="form-control w-full max-w-xs"
        >
          <label className="label">
            <span className="label-text">Email</span>
          </label>
          <input
            type="email"
            className={`input input-bordered w-full max-w-xs ${
              formik.touched.email && formik.errors.email ? "input-error" : ""
            }`}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-error">{formik.errors.email}</div>
          ) : null}

          <label className="label">
            <span className="label-text">Password</span>
          </label>
          <input
            type="password"
            className={`input input-bordered w-full max-w-xs ${
              formik.touched.password && formik.errors.password
                ? "input-error"
                : ""
            }`}
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-error">{formik.errors.password}</div>
          ) : null}

          <button
            type="submit"
            className="btn btn-primary my-2 w-full"
            disabled={loading}
          >
            Register
          </button>
          <Link to="/login" className="text-primary underline">
            Already Registered? Sign In
          </Link>
        </form>
      </div>
    </>
  );
};

export default Register;
