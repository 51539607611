import React, { useEffect, useState } from "react";
import { collection, onSnapshot, doc, getDoc } from "@firebase/firestore";
import { db, auth } from "../firebase/firebase";
import { Tab, TabList, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Oval } from "react-loader-spinner";
import { useAuthState } from "react-firebase-hooks/auth";
import NavBar from "../components/NavBar";

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("cbc");
  const [user, loadingAuth] = useAuthState(auth);
  const [isPremium, setIsPremium] = useState(false);
  const videosCollectionRef = collection(db, "videos");

  useEffect(() => {
    const unsub = onSnapshot(
      videosCollectionRef,
      (snapshot) => {
        const stuff = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        setVideos(stuff);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );

    return () => {
      unsub();
    };
  }, [videosCollectionRef]);

  useEffect(() => {
    const checkSubscription = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (
          userDoc.exists() &&
          userDoc.data().subscriptionStatus === "premium"
        ) {
          setIsPremium(true);
        }
      }
    };

    checkSubscription();
  }, [user]);

  const handleTabChange = (category) => {
    setSelectedCategory(category);
  };

  const filteredVideos = videos.filter(
    (video) => video.category === selectedCategory
  );

  return (
    <>
      <NavBar />
      {!isPremium && (
        <div className="bg-indigo-600 px-4 py-3 text-white">
          <p className="text-center text-sm font-medium">
            Enjoying the content?{" "}
            <a
              href={`https://wa.me/+254701967152?text=Hi.%20I%20am%20interested%20in%20paying%20for%20premium%20to%20access%20the%20full%20content`}
              className="inline-block underline"
            >
              Pay for Premium
            </a>{" "}
            to access all videos!
          </p>
        </div>
      )}
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto">
          <Tabs>
            <TabList>
              <Tab onClick={() => handleTabChange("cbc")}>CBC</Tab>
              <Tab onClick={() => handleTabChange("chuo-kikuu")}>SECONDARY</Tab>
              <Tab onClick={() => handleTabChange("hadithi")}>STORIES</Tab>
            </TabList>
          </Tabs>
          {loading || loadingAuth ? (
            <div
              className="flex justify-center items-center h-screen"
              style={{ minHeight: "50vh" }}
            >
              <Oval color="#00BFFF" height={80} width={80} />
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
              {filteredVideos.length === 0 ? (
                <div className="p-4 md:w-1/3">
                  <div className="h-full w-full p-10 border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden md:w-80 lg:w-96">
                    <p className="text-lg font-medium text-gray-900 mb-3">
                      Hakuna video zinazopatikana katika jamii hii.
                    </p>
                    <p className="leading-relaxed mb-3">
                      Tutawaletea maudhui hivi karibuni. Tafadhali rudia tena
                      baadaye.
                    </p>
                  </div>
                </div>
              ) : (
                filteredVideos.map((video, index) => {
                  if (index === 0 || isPremium) {
                    return (
                      <div className="p-4 md:w-1/3" key={video.id}>
                        <div className="h-full w-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden md:w-80 lg:w-96">
                          <video
                            className="lg:h-64 md:h-36 w-full object-cover object-center cursor-pointer"
                            controls
                            onClick={() => this.play()}
                            loop
                            poster={video.attachment}
                            preload="metadata"
                          >
                            <source
                              src={`${video.attachment}#t=0.5`}
                              type="video/mp4"
                            />
                          </video>
                          <div className="p-6">
                            <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                              {video.title.toUpperCase()}
                            </h1>
                            <p className="leading-relaxed mb-3">
                              {video.description}
                            </p>
                            <div className="flex items-center flex-wrap ">
                              <a
                                href={video.attachment}
                                target="_blank"
                                rel="noreferrer"
                                className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                              >
                                Play
                                <svg
                                  className="w-4 h-4 ml-2"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M5 12h14" />
                                  <path d="M12 5l7 7-7 7" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="p-4 md:w-1/3" key={video.id}>
                        <div className="h-full w-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden md:w-80 lg:w-96">
                          <div className="flex items-center justify-center h-64 md:h-36 w-full bg-gray-200">
                            <div className="text-gray-500 text-lg flex flex-col justify-center items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width={50}
                                height={50}
                                viewBox="0 0 50 50"
                                fill="gray"
                              >
                                <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.300781 20 6 21.300781 6 23 L 6 47 C 6 48.699219 7.300781 50 9 50 L 41 50 C 42.699219 50 44 48.699219 44 47 L 44 23 C 44 21.300781 42.699219 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 25 30 C 26.699219 30 28 31.300781 28 33 C 28 33.898438 27.601563 34.6875 27 35.1875 L 27 38 C 27 39.101563 26.101563 40 25 40 C 23.898438 40 23 39.101563 23 38 L 23 35.1875 C 22.398438 34.6875 22 33.898438 22 33 C 22 31.300781 23.300781 30 25 30 Z" />
                              </svg>

                              <p className="text-center">Premium Content</p>
                            </div>
                          </div>
                          <div className="p-6">
                            <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                              {video.title.toUpperCase()}
                            </h1>
                            <p className="leading-relaxed mb-3">
                              {video.description}
                            </p>
                            <div className="flex items-center flex-wrap ">
                              <span className="text-gray-500 inline-flex items-center md:mb-2 lg:mb-0">
                                Play
                                <svg
                                  className="w-4 h-4 ml-2"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M5 12h14" />
                                  <path d="M12 5l7 7-7 7" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Videos;
