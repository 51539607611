import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "../chatbot/config.js";
import MessageParser from "../chatbot/MessageParser.js";
import ActionProvider from "../chatbot/ActionProvider.js";
// components
import Footer from "../components/Footer";
import "./styles/blobs.css";
import { db } from "../firebase/firebase.js";
import { getDocs, collection } from "@firebase/firestore";
import logo from "../assets/logo.jpg";

const Landing = () => {
  const [quotes, setquotes] = useState([]);

  const quotesCollectionRef = collection(db, "quotes");

  useEffect(() => {
    const getquotes = async () => {
      const data = await getDocs(quotesCollectionRef);
      setquotes(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getquotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="navbar bg-primary">
        <div className="navbar-start">
          <div className="navbar-start">
            <NavLink to="/" className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="navbar-logo w-20 h-20 rounded-full"
              />
              <span className="btn btn-ghost text-xl md:text-3xl text-secondary custom-font">
                Mswahilispot
              </span>
            </NavLink>
          </div>
        </div>
        <div className="navbar-end">
          <NavLink className="btn text-white" to="/login">
            Log In
          </NavLink>
        </div>
      </div>
      <div className="blobs px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="flex items-start justify-start mb-4 rounded-full">
              <img
                src={logo}
                alt="Logo"
                className="navbar-logo w-20 h-20 rounded-full"
              />
            </div>
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Mswahilispot
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                We strive to provide education services and language resources
                in Kiswahili to the public.
              </p>
              <p className="text-base text-gray-700 font-bold md:text-lg">
                Furthermore, we offer translation services from English to
                Kiswahili and Gikuyu to Kiswahili.
              </p>
            </div>
            <div className="flex items-center">
              <Link to="/register" className="btn btn-primary mr-2">
                Register
              </Link>
              <a
                href="https://wa.me/+254701967152"
                aria-label=""
                className="btn btn-outline btn-primary"
              >
                Translation Services
              </a>
            </div>
            <div className="flex">
              <h2 className="max-w-lg mt-4 font-sans text-2xl tracking-tight text-gray-900 sm:text-3xl sm:leading-none font-bold italic">
                Quote of the{" "}
                <span className="inline-block text-primary">Day</span>
              </h2>
              <span className="mx-2 max-w-lg mt-4 font-sans text-2xl tracking-tight text-gray-900 sm:text-3xl sm:leading-none font-bold italic">
                |
              </span>
              <h2 className="max-w-lg mt-4 font-sans text-2xl tracking-tight text-gray-900 sm:text-3xl sm:leading-none font-bold italic">
                Kauli ya <span className="inline-block text-primary">Siku</span>
              </h2>
            </div>
            <blockquote className="text-xl italic font-semibold text-white bg-secondary p-5 mt-4 rounded-2xl">
              <svg
                aria-hidden="true"
                className="w-10 h-10 text-gray-400 dark:text-white"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                />
              </svg>
              <div>
                {quotes.map((quote) => (
                  <p key={crypto.randomUUID()}>{`"${quote.quote}"`}</p>
                ))}
              </div>
            </blockquote>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:justify-end">
            <div className="px-3">
              <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Landing;
