import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Reset = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Barua pepe ni lazima")
      .email("Barua pepe si sahihi"),
  });

  const handleSubmit = async (values) => {
    try {
      await sendPasswordResetEmail(auth, values.email);
      toast.success("Barua pepe ya kubadili nenosiri imetumwa.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/login");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        toast.error("Hakuna mtumiaji uliyepatikana na barua pepe hii.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(
          "Kuna hitilafu katika kubadili nenosiri. Tafadhali jaribu tena.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div className="navbar bg-primary text-primary-content">
        <NavLink to="/" className="btn btn-ghost normal-case text-xl">
          Mswahilispot
        </NavLink>
      </div>
      <div className="flex flex-col justify-center items-center mt-40">
        <form
          onSubmit={formik.handleSubmit}
          className="form-control w-full max-w-xs"
        >
          <label className="label">
            <span className="label-text">Email</span>
          </label>
          <input
            type="email"
            className={`input input-bordered w-full max-w-xs ${
              formik.touched.email && formik.errors.email ? "input-error" : ""
            }`}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-error">{formik.errors.email}</div>
          ) : null}

          <button type="submit" className="btn btn-primary my-2 w-full">
            Change Password
          </button>
          <Link to="/login" className="btn btn-secondary">
            Back
          </Link>
        </form>
      </div>
    </>
  );
};

export default Reset;
