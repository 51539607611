import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { onSnapshot, doc } from "@firebase/firestore";

import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { db } from "../firebase/firebase";
import "./styles/blobs.css";

const Article = () => {
  const [article, setArticle] = useState({});
  const { id } = useParams();

  const docRef = doc(db, "articles", id);

  useEffect(() => {
    onSnapshot(docRef, (doc) => setArticle(doc.data(), doc.id));
  }, [docRef]);

  return (
    <>
      <NavBar />
      <div className="flex flex-col justify-center items-center sparse">
        <h1 className="font-bold m-5 text-3xl text-center">{article.title}</h1>
        <img
          alt="blog-pic"
          className="m-4 sm:w-3/4 md:w-2/3 lg:w-1/2"
          src={article.attachment}
        />
        <p className="m-4 sm:w-3/4 md:w-2/3 lg:w-1/2">{article.content}</p>
      </div>
      <Footer />
    </>
  );
};

export default Article;
