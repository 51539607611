import { signOut } from "firebase/auth";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/logo.jpg";

import { auth } from "../firebase/firebase";

const NavBar = () => {
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    await signOut(auth);
    navigate("/");
  };

  return (
    <>
      <div className="navbar bg-primary">
        <div className="navbar-start">
          <NavLink to="/menu" className="flex items-center">
            <img
              src={logo}
              alt="Logo"
              className="navbar-logo w-20 h-20 rounded-full"
            />
            <span className="btn btn-ghost text-xl md:text-3xl text-secondary custom-font">
              Mswahilispot
            </span>
          </NavLink>
        </div>
        <div className="navbar-end">
          <NavLink className="btn text-white" onClick={handleLogout}>
            Log Out
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default NavBar;
